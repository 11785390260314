import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "gatsby-image/withIEPolyfill"
import { graphql, Link } from "gatsby"

const Sintex = ({ data }) => {
  return (
    <Layout>
      <SEO title="Serviço Autorizado Pela Sintex | Cedilar" />
      <div className="lg:flex my-4">
        <div className="m-6 lg:w-2/3">
          <h1 className="text-2xl font-bold">
            Assistência Técnica de Aparelhos Eletrodomésticos Cedilar Porto
            Alegre.
          </h1>
          <p>
            Consertamos uma grande variedade de aparelhos que você utiliza no
            seu dia a dia. Navegue nosso site para tirar dúvidas sobre seu
            produto ou entre em contato conosco para clarificar informações
            necessárias.
          </p>
          <p>
            Visite nossa página <Link to="/contato">CONTATO</Link> para acessar
            o mapa do Google e buscar direções até nosso estabelecimento.
          </p>
          <p>
            Recomendamos que visite a página de PERGUNTAS FREQUENTS para
            clarificar dúvidas simples sobre seu produto.
          </p>
        </div>
        <div className="lg:w-1/3">
          <Image
            className="lazyload"
            fluid={data?.eletrodomesticosCozinha?.childImageSharp?.fluid}
            alt="Conserto de Aparelhos Eletrodomésticos"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    eletrodomesticosCozinha: file(
      relativePath: { eq: "Eletrodomesticos-Cozinha.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default Sintex
